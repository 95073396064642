body {
  padding: 0;
  margin: 0;
}
#root{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.container {
  height: 100vh;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}