/* .HomePage{
    width: 100%;
} */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.dashCount{
    width: 20vw;
    height: 16vh;
    background: red;
    border-radius: 15px;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashCounts h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    color: #fff;
}

.dashCounts h4 {
    font-size: 16px;
    color: #fff;
    font-stretch: condensed;
}